import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faScaleBalanced, faLightbulb, faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";

const CaseAnalysisCard = ({ analysisData, handleDownloadPDF }) => {
  if (!analysisData) return null;

  const { analysis, pdf_url } = analysisData;

  return (
    <div className="p-6 bg-white rounded-lg shadow-md space-y-6">
      {/* Case Header */}
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold text-gray-900">{analysis.caseTitle || "N/A"}</h2>
        <span className="text-sm font-bold text-gray-600">Case No: {analysis.caseNumber || "N/A"}</span>
      </div>

      {/* Decision Date and Language */}
      <div className="flex justify-between">
        <span className="font-semibold text-gray-600">Decision Date: {analysis.dateOfDecision || "N/A"}</span>
        <span className="text-gray-600"><strong>Language: </strong> {analysis.language || "N/A"}</span>
      </div>

      {/* Judges */}
        <section className="space-y-3">
        <div className="text-gray-800 font-semibold">Judges:</div>
        <ul className="list-disc pl-5 text-gray-600">
            {analysis.judges?.length
            ? analysis.judges.map((judge, index) => <li key={index}>{judge}</li>)
            : "N/A"}
        </ul>
        </section>

        {/* Legislations and Acts */}
        <section className="space-y-3">
        <div className="text-gray-800 font-semibold">Legislations and Acts:</div>
        <ul className="list-disc pl-5 text-gray-600">
            {analysis.legislationsActs?.length
            ? analysis.legislationsActs.map((act, index) => <li key={index}>{act}</li>)
            : "N/A"}
        </ul>
        </section>

        {/* Advocates */}
        <section className="space-y-3">
        <div className="text-gray-800 font-semibold">Advocates:</div>
        <div className="flex flex-col">
            <div className="text-gray-600">
            <strong>Appellant:</strong> {analysis.advocates?.appellant?.join(", ") || "N/A"}
            </div>
            <div className="text-gray-600">
            <strong>Respondent:</strong> {analysis.advocates?.respondent?.join(", ") || "N/A"}
            </div>
        </div>
        </section>

        {/* Key Issues and Findings */}
        <section className="space-y-5">
        <div className="text-gray-800 font-semibold">Key Issues and Findings:</div>
        {analysis.keyIssuesAndFindings?.length ? (
            analysis.keyIssuesAndFindings.map((issue, index) => (
            <div key={index} className="bg-white p-7 rounded-lg shadow-md hover:shadow-xl transition-shadow space-y-6 border border-gray-300 hover:border-gray-400">
            {/* Issue */}
            <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faExclamation} className="ml-1 text-blue-500" style={{ fontSize: '20px'}}/>
                <div className="font-medium text-gray-700">Issue:</div>
            </div>
            <p className="text-gray-600">{issue.issue || "N/A"}</p>

            {/* Holding */}
            <div className="flex items-center gap-1">
                <FontAwesomeIcon icon={faScaleBalanced} className="text-green-500" style={{ fontSize: '13px'}}/>
                <div className="font-medium text-gray-700">Holding:</div>
            </div>
            <p className="text-gray-600">{issue.holding || "N/A"}</p>

            {/* Reasoning */}
            <div className="flex items-center gap-1">
                <FontAwesomeIcon icon={faLightbulb} className="text-yellow-500" />
                <div className="font-medium text-gray-700">Reasoning:</div>
            </div>
            <p className="text-gray-600">{issue.reasoning || "N/A"}</p>

            {/* Implications */}
            <div className="flex items-center gap-1">
                <FontAwesomeIcon icon={faCircleArrowUp} className="text-red-500" />
                <div className="font-medium text-gray-700">Implications:</div>
            </div>
            <p className="text-gray-600">{issue.implications || "N/A"}</p>
            </div>
            ))
        ) : (
            "N/A"
        )}
        </section>

        {/* Neutral Citation */}
        <section className="space-y-3">
        <div className="text-gray-800 font-semibold">Neutral Citation:</div>
        <p className="text-gray-600">{analysis.neutralCitation || "N/A"}</p>
        </section>

      {/* PDF Download */}
      {pdf_url && (
        <div className="mt-4">
          <button
            onClick={handleDownloadPDF}
            className="inline-block bg-[#5E48FC] text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600 mb-6"
          >
            Download PDF
          </button>
        </div>
      )}
    </div>
  );
};

export default CaseAnalysisCard;

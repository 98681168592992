import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Efiling from "./Efiling/Efiling";
import DefectAnalysis from "./DefectsAnalysis/DefectAnalysis";
import EntityExtraction from "./EntityExtration/EntityExtraction";
import LandingPage from "./LandingPage/LandingPage";
import Navbar from "./Navbar/Navbar";
import AccountSetting from "./AccountSettings/AccountSetting";
import MobileHeader from "./MobileHeader/MobileHeader";
import Home from "./Home";
import DefectTab from "./DefectsAnalysis/DefectTab";
import EntityExtraction1 from "./EntityExtration/EntityExtraction1";
import EfilingHistory from "./E-Filing/EfilingHistory";
import UserManagement from "./UserManagement/UserManagement";
import SpeechToText from "./SpeechToText/SpeechToText";
import VideoPlayer from "./SpeechToText/VideoPlayer";
import SimilaritySearch from "./SimilaritySearch/SimilaritySearch";
import SelectedSimilarity from "./SimilaritySearch/SelectedSimilarity";
import RealTimeSTT from "./RealTime/RealTimeSTT";
import AudioPlayer from "./RealTime/AudioPlayer";
import AddedDefectsHistory from "./DefectsAnalysis/AddedDefectsHistory";
import JudgementAnalysis from "./JudgementAnalysis/JudgementAnalysis";
export const UserContext = createContext();

function Routing() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem("isLoggedIn") === "true";
  });

  const [userDetails, setUserDetails] = useState(() => {
    const storedData = localStorage.getItem("userDetails");
    return storedData ? JSON.parse(storedData) : {};
  });

  const [activeNavbar, setActiveNavbar] = useState(() => {
    const storedData = localStorage.getItem("activeNavbar");
    return storedData ? JSON.parse(storedData) : "";
  });

  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
    localStorage.setItem("activeNavbar", JSON.stringify(activeNavbar));
  }, [isLoggedIn, userDetails, activeNavbar]);

  return (
    <UserContext.Provider
      value={{
        setIsLoggedIn,
        isLoggedIn,
        userDetails,
        setUserDetails,
        setActiveNavbar,
        activeNavbar,
      }}
    >
      <Router>
        {isLoggedIn ? (
          <div className="sm:flex relative h-full w-full">
            <div className="w-1/5 hidden sm:block sm:fixed">
              <Navbar />
            </div>
            <div className="w-full sm:hidden fixed top-0 z-10">
              <MobileHeader />
            </div>
            <div className="sm:w-4/5 w-full pt-10 sm:pt-0 ml-auto">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/account-setting" element={<AccountSetting />} />
                {userDetails?.userData?.authRole !== "User" ? (
                  <Route
                    path="/users-management"
                    element={<UserManagement />}
                  />
                ) : null}
                <Route path="/E-Filing" element={<Efiling />} />
                <Route
                  path="/EntityExtraction"
                  element={<EntityExtraction1 />}
                />
                <Route
                  path="/SelectedExtraction"
                  element={<EntityExtraction />}
                />
                <Route path="/DefectAnalysis" element={<DefectAnalysis />} />
                <Route path="/JudgementAnalysis" element={<JudgementAnalysis />} />
                <Route path="/SpeechToText" element={<SpeechToText />} />
                <Route
                  path="/SimilaritySearch"
                  element={<SimilaritySearch />}
                />
                <Route path="/SelectedVideo" element={<VideoPlayer />} />
                <Route path="/EfilingHistory" element={<EfilingHistory />} />
                <Route path="/SelectedFileAnalysis" element={<DefectTab />} />
                <Route
                  path="/SelectedFileAnalysis/AuditHistory"
                  element={<AddedDefectsHistory />}
                />
                <Route
                  path="/SelectedSimilarity"
                  element={<SelectedSimilarity />}
                />
                <Route path="/RealTimeSTT" element={<RealTimeSTT />} />
                <Route path="/SelectedAudio" element={<AudioPlayer />} />
              </Routes>
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<LandingPage />} />
          </Routes>
        )}
      </Router>
    </UserContext.Provider>
  );
}
export default Routing;

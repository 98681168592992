import React from "react";

const Loader = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      {/* Circle animation */}
      <div style={{ borderTop: "4px solid blue", borderRight: "4px solid transparent", borderRadius: "50%", width: "3rem", height: "3rem", animation: "spin 1s linear infinite" }}></div>
      <p className="text-gray-700 font-semibold mt-3">Processing file, please wait...</p>
    </div>
  );
};

export default Loader;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faUserGroup,
  // faUser,
  faArrowRightFromBracket,
  faFileLines,
  // faFileAudio,
  // faSquarePollVertical,
  // faFileWaveform,
  // faFileContract,
  // faMicrophoneLines,
  // faMessage,
  // faBookBookmark,
  // faScaleBalanced,
  // faBookOpenReader,
} from "@fortawesome/free-solid-svg-icons";
import nyaayLogo1 from "../../Images/Main1.jpg";
import nyaayLogo2 from "../../Images/Main2.jpg";
import { UserContext } from "../Routing";
// import LogoutModal from "./LogoutModal";

function Navbar() {
  const userState = useContext(UserContext);
  const {
    setShowNavigation = () => {},
    setIsLoggedIn = () => {},
    // userDetails = {},
    activeNavbar = "",
    setActiveNavbar = () => {},
  } = userState || {};
  // const { userData = {} } = userDetails;
  // const { authRole } = userData || {};

  const navigate = useNavigate();

  // const [showLogOutModal, setShowLogOutModal] = useState(false);
  const handleLogOut = () => {
    setShowNavigation(false);
    navigate("/");
    setIsLoggedIn(false);
  };

  return (
    <div className="bg-[#FFFFFF] shadow-xl w-full pt-4 h-screen">
      <div
        className="hidden sm:flex sm:ms-20"
        onClick={() => setActiveNavbar("")}
      >
        <img
          src={nyaayLogo1}
          className="w-1/6 h-1/6 cursor-pointer"
          alt="NyaayLogo"
          onClick={() => navigate("/")}
        />
        <div className="flex items-end">
          <img
            src={nyaayLogo2}
            className="w-1/2 h-1/2 cursor-pointer ml-2"
            alt="NyaayLogo"
            onClick={() => navigate("/")}
          />
        </div>
      </div>

      <ul className="mt-4">
        <li
          onClick={() => {
            navigate("/DefectAnalysis");
            setShowNavigation(false);
            setActiveNavbar("defect_and_E-Filing");
          }}
          className={`${
            activeNavbar === "defect_and_E-Filing"
              ? "bg-[#5E48FC] text-white"
              : "text-[#40566D] hover:bg-gray-200"
          } px-4 py-1 font-normal text-sm`}
        >
          <button className="p-1.5 md:flex justify-center items-center">
            <FontAwesomeIcon icon={faFileLines} />
            <span className="ms-3">Defect & E-filing Automation</span>
          </button>
        </li>
        <li
          onClick={() => {
            navigate("/JudgementAnalysis");
            setShowNavigation(false);
            setActiveNavbar("judgement_analysis");
          }}
          className={`${
            activeNavbar === "judgement_analysis"
              ? "bg-[#5E48FC] text-white"
              : "text-[#40566D] hover:bg-gray-200"
          } px-4 py-1 font-normal text-sm`}
        >
          <button className="p-1.5 md:flex justify-center items-center">
            <FontAwesomeIcon icon={faFileLines} />
            <span className="ms-3">Judgement Analysis</span>
          </button>
        </li>
        {/* <li
          onClick={() => {
            navigate("/E-Filing");
            setShowNavigation(false);
            setActiveNavbar("E-Filing");
          }}
          className={`${
            activeNavbar === "E-Filing"
              ? "bg-[#5E48FC] text-white"
              : "text-[#40566D] hover:bg-gray-200"
          } px-4 py-1 font-normal text-sm`}
        >
          <button className="p-1.5 md:flex justify-center items-center">
            <FontAwesomeIcon icon={faFileLines} />
            <span className="ms-3">E-filing Automation</span>
          </button>
        </li> */}

        {/* <li
          onClick={() => {
            navigate("/EntityExtraction");
            setShowNavigation(false);
            setActiveNavbar("Entity Extraction");
          }}
          className={`${
            activeNavbar === "Entity Extraction"
              ? "bg-[#5E48FC] text-white"
              : "text-[#40566D] hover:bg-gray-200"
          } px-4 py-1 font-normal text-sm`}
        >
          <button className="p-1.5 md:flex justify-center items-center">
            <FontAwesomeIcon icon={faFileContract} />
            <span className="ms-3"> Extraction & Summarization</span>
          </button>
        </li> */}

        {/* <li
          onClick={() => {
            navigate("/DefectAnalysis");
            setShowNavigation(false);
            setActiveNavbar("Defect Analysis");
          }}
          className={`${
            activeNavbar === "Defect Analysis"
              ? "bg-[#5E48FC] text-white"
              : "text-[#40566D] hover:bg-gray-200"
          } px-4 py-1 font-normal text-sm`}
        >
          <button className="p-1.5 md:flex justify-center items-center">
            <FontAwesomeIcon icon={faFileWaveform} />
            <span className="ms-3"> Defects Analysis</span>
          </button>
        </li> */}

        {/* <li
          onClick={() => {
            navigate("/SpeechToText");
            setShowNavigation(false);
            setActiveNavbar("Speech to text");
          }}
          className={`${
            activeNavbar === "Speech to text"
              ? "bg-[#5E48FC] text-white"
              : "text-[#40566D] hover:bg-gray-200"
          } px-4 py-1 font-normal text-sm`}
        >
          <button className="p-1.5 md:flex justify-center items-center">
            <FontAwesomeIcon icon={faFileAudio} />
            <span className="ms-3">Audio Transcription</span>
          </button>
        </li> */}

        {/* <li
          onClick={() => {
            navigate("/SimilaritySearch");
            setShowNavigation(false);
            setActiveNavbar("Similarity Search");
          }}
          className={`${
            activeNavbar === "Similarity Search"
              ? "bg-[#5E48FC] text-white"
              : "text-[#40566D] hover:bg-gray-100"
          } px-4 py-1 font-normal text-sm`}
        >
          <button className="p-1.5 md:flex justify-center items-center">
            <FontAwesomeIcon icon={faSquarePollVertical} />
            <span className="ms-3">Similar Case Bunching</span>
          </button>
        </li> */}

        {/* <li
          onClick={() => {
            navigate("/RealTimeSTT");
            setShowNavigation(false);
            setActiveNavbar("Real Time STT");
          }}
          className={`${
            activeNavbar === "Real Time STT"
              ? "bg-[#5E48FC] text-white"
              : "text-[#40566D] hover:bg-gray-100"
          } px-4 py-1 font-normal text-sm`}
        >
          <button className="p-1.5 md:flex justify-center items-center">
            <FontAwesomeIcon icon={faMicrophoneLines} />
            <span className="ms-3"> Live Transcription</span>
          </button>
        </li> */}

        {/* <li
          onClick={() => {
            window.open("https://juris.indikaai.com", "_blank");
            // navigate("/");
            setShowNavigation(false);
            setActiveNavbar("Conversational Bot");
          }}
          className={`${
            activeNavbar === "Conversational Bot"
              ? "bg-[#5E48FC] text-white"
              : "text-[#40566D] hover:bg-gray-100"
          } px-4 py-1 font-normal text-sm`}
        >
          <button className="p-1.5 md:flex justify-center items-center">
            <FontAwesomeIcon icon={faMessage} />
            <span className="ms-3">Legal Research</span>
          </button>
        </li> */}
        {/* <li
          onClick={() => {
            navigate("/");
            setShowNavigation(false);
            setActiveNavbar("Case Law Mapping");
          }}
          className={`${
            activeNavbar === "Case Law Mapping"
              ? "bg-[#5E48FC] text-white"
              : "text-[#40566D] hover:bg-gray-100"
          } px-4 py-1 font-normal text-sm`}
        >
          <button className="p-1.5 md:flex justify-center items-center">
            <FontAwesomeIcon icon={faBookBookmark} />
            <span className="ms-3">Case Law Mapping</span>
          </button>
        </li> */}
        {/* <li
          onClick={() => {
            navigate("/");
            setShowNavigation(false);
            setActiveNavbar("Legal Research");
          }}
          className={`${
            activeNavbar === "Legal Research"
              ? "bg-[#5E48FC] text-white"
              : "text-[#40566D] hover:bg-gray-100"
          } px-4 py-1 font-normal text-sm`}
        >
          <button className="p-1.5 md:flex justify-center items-center">
            <FontAwesomeIcon icon={faBookOpenReader} />
            <span className="ms-3">Legal Research</span>
          </button>
        </li> */}
        {/* <li
          onClick={() => {
            navigate("/");
            setShowNavigation(false);
            setActiveNavbar("Legal Judgement Prediction");
          }}
          className={`${
            activeNavbar === "Legal Judgement Prediction"
              ? "bg-[#5E48FC] text-white"
              : "text-[#40566D] hover:bg-gray-100"
          } px-4 py-1 font-normal text-sm`}
        >
          <button className="p-1.5 md:flex justify-center items-center">
            <FontAwesomeIcon icon={faScaleBalanced} />
            <span className="ms-3"> Legal Judgement Prediction</span>
          </button>
        </li> */}
      </ul>
      <div className="my-6">{/* <hr /> */}</div>
      <div>
        <ul>
          {/* <li
            onClick={() => {
              navigate("/account-setting", {
                state: { userData },
              });
              setShowNavigation(false);
              setActiveNavbar("Setting");
            }}
            className={`${
              activeNavbar === "Setting"
                ? "bg-[#5E48FC] text-white"
                : "text-[#40566D] hover:bg-gray-200"
            } px-4 py-1 font-normal text-sm`}
          >
            <button className="p-1.5 md:flex justify-center items-center">
              <FontAwesomeIcon icon={faUser} />
              <span className="ms-3"> My account</span>
            </button>
          </li> */}
          {/* {authRole !== "User" ? (
            <li
              onClick={() => {
                navigate("/users-management", {
                  state: { userData },
                });
                setShowNavigation(false);
                setActiveNavbar("userManagement");
              }}
              className={`${
                activeNavbar === "userManagement"
                  ? "bg-[#5E48FC] text-white"
                  : "text-[#40566D] hover:bg-gray-200"
              } px-4 py-1 font-normal text-sm`}
            >
              <button className="p-1.5 md:flex justify-center items-center">
                <FontAwesomeIcon icon={faUserGroup} />
                <span className="ms-2"> User Management</span>
              </button>
            </li>
          ) : null} */}
          <li
            className="text-[#40566D] hover:bg-gray-200 px-4 py-1 font-normal text-sm"
            onClick={() => {
              // setShowLogOutModal(true);
              handleLogOut();
            }}
          >
            <button className="p-1.5">
              <FontAwesomeIcon icon={faArrowRightFromBracket} />
              <span className="ms-2"> Log Out</span>
            </button>
          </li>
        </ul>
      </div>
      {/* {showLogOutModal ? (
        <LogoutModal
          showLogOutModal={showLogOutModal}
          setShowLogOutModal={setShowLogOutModal}
          handleLogOut={handleLogOut}
        />
      ) : null} */}
    </div>
  );
}
export default Navbar;

import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FileUploadModal from "../common/FileUploadModal";
import axios from "axios";
import Loader from '../common/Loader.js';
import CaseAnalysisCard from "./CaseAnalysisCard.js";
const { REACT_APP_API_KEY } = process.env;

function JudgementAnalysis() {
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [analysisData, setAnalysisData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = async (files) => {
    if (!files || files.length === 0) {
      toast.error("Please select a file before uploading.");
      return;
    }
  
    const file = files[0]; 
    try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", file);

        const token = JSON.parse(localStorage.getItem("userDetails")).token;
        console.log(token);
        const response = await axios.post(
          `${REACT_APP_API_KEY}/analyze`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );

      if (response.status === 200) {
        setAnalysisData(response.data);
        toast.success("File uploaded successfully!");
      } else {
        throw new Error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Failed to upload file. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadPDF = async () => {
    if (analysisData && analysisData.pdf_url) {
      const pdfId = analysisData.pdf_url.replace('/download-pdf/', '');
      const token = JSON.parse(localStorage.getItem("userDetails")).token;
      try {
        const response = await axios.get(
          `${REACT_APP_API_KEY}/downloadHeadnote?id=${pdfId}`, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: 'blob', // Ensure the response is a PDF (blob)
            withCredentials: true,
          }
        );
  
        
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response.data); // Create object URL for the file
        const pdfName=pdfId.replace('.pdf','');
        link.download = `${pdfName}`; 
        link.click(); 
        toast.success("Downloading PDF...");
      } catch (error) {
        console.error("Error downloading PDF:", error);
        toast.error("Failed to download PDF. Please try again.");
      }
    } else {
      toast.error("No PDF available for download.");
    }
  };
  
  const formatData = () => {
    if (!analysisData) return null;
    return (
      <CaseAnalysisCard
        analysisData={analysisData}
        handleDownloadPDF={handleDownloadPDF}
      />
    );
  };


  return (
    <div className="sm:px-4 w-full">
      <ToastContainer />
      <div className="flex mx-2 justify-between items-center">
        <div className="text-[#40566D] text-xl my-3 font-medium">Judgement Analysis</div>
        <div>
          <button
            onClick={() => setShowFileUploadModal(true)}
            className="flex items-center px-4 py-1.5 bg-[#5E48FC] text-[#FFFFFF] rounded text-sm font-semibold"
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ms-2"> Upload Document</span>
          </button>
        </div>
      </div>
      <hr className="sm:-mx-4" />
      {showFileUploadModal && (
        <FileUploadModal
          showFileUploadModal={showFileUploadModal}
          setShowFileUploadModal={setShowFileUploadModal}
          handleFileUpload={handleFileUpload}
          currentTab="JudgementAnalysis"
          isLoading={isLoading}
          heading="Judgement Analysis"
          subHeading="Upload the file for judgement analysis"
        />
      )}
      {isLoading ? (
        <div className="mt-20 text-center">
        <Loader />
      </div>
    ) : (
        analysisData && <div className="mt-4">{formatData()}</div>
      )}
    </div>
  );
}

export default JudgementAnalysis;

